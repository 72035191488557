@use '../../scss/utils/variables';
@use '../../scss/utils/mixins';

.news {
  width: 100%;
  margin-top: 3rem;

  &__errorMessage {
    text-align: center;
  }
}


@media screen and (min-width: 450px) {

}