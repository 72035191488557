@use "../../scss/utils/variables";

.backButton {
    outline: none;
    background-color: transparent;
    border: 1px solid variables.$link;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    cursor: pointer;
    transition: variables.$transition;

    svg {
        width: 100%;
        height: 100%;

        path {
            fill: variables.$link;
            transition: variables.$transition;
        }
    }

    &:hover {
        border-color: variables.$orange;

        svg path {
            fill: variables.$orange;
        }
    }
}
