.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: .5rem;
}

.footer__link {
  padding: .5rem;
  svg {
    width: 100%;
    height: 100%;
  }
}

.footer__linksItem {
  width: 2.5rem;
  height: 2.5rem;
  &:first-child {
    width: 2.3rem;
    height: 2.3rem;
  }
}

