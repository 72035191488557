@use '../../scss/utils/variables';

.articles {
  width: 100%;
  margin-top: 3rem;

  &__errorMessage {
    text-align: center;
  }
}
