@use "../../scss/utils/variables";

.post {
    width: 100%;
    border-radius: 15px;
    // backdrop-filter: blur(10px);
    // background-color: rgba(255, 255, 255, .5);
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 25px 0 rgba(13, 17, 23, 0.05);
    margin-bottom: 4rem;

    &__title {
        display: block;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: center;
        color: variables.$orange;
    }

    &__date {
        opacity: .7;
        user-select: none;
        cursor: default;
        margin-bottom: 1rem;
    }
}

.compactPost {
    width: 800px;
    height: 500px;
    overflow: hidden;
    position: relative;

    img {
        display: none;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    &::after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        background: linear-gradient(0deg, white 10%, rgba(0, 0, 0, 0) 100%);
    }

    .readMore {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
        background-color: variables.$link;
        color: white;
        border-radius: 10000px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 14px;

        &:hover {
            background-color: lighten(variables.$link, 5%);
            color: white;
        }
    }
}

@media screen and (min-width: 450px) {
    .post {
        &__title {
            font-size: 2.2rem;
        }
    }
}

@media screen and (min-width: 1000px) {
    .post {
        &__title {
            font-size: 3rem;
        }
    }
}

