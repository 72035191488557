@use "../../../scss/utils/variables";

.post {
    width: 100%;
    border-radius: 15px;
    background-color: white;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 25px 0 rgba(13, 17, 23, 0.05);

    time {
        opacity: .7;
        user-select: none;
        cursor: default;
    }

    &__title {
        display: block;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: center;
        color: variables.$orange;
    }



    &__backButton {
        margin-top: 20px;
    }
}


@media screen and (min-width: 1000px) {
    .post {
        &__title {
            font-size: 3rem;
        }
    }
}

@media screen and (min-width: 450px) {
    .post {
        &__title {
            font-size: 2rem;
        }
    }
}