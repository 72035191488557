@use "../../scss/utils/variables";

.article {
    width: 100%;
    border-radius: 15px;
    background-color: white;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 25px 0 rgba(13, 17, 23, 0.05);
    position: relative;

    &__title {
        color: variables.$orange;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    &__readMore {
        position: absolute;
        bottom: 2rem;
        z-index: 1000;
        background-color: rgba(variables.$link, .8);
        backdrop-filter: blur(.5rem) saturate(180%);
        color: variables.$white;
        border-radius: 10000px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 1.4rem;
        box-shadow: 0 0 25px 0 rgba(13, 17, 23, .1);

        &:hover {
            color: variables.$white;
            background-color: rgba(lighten(variables.$link, 5%), .8);
        }
    }

    time {
        opacity: .7;
        user-select: none;
        cursor: default;
        margin-bottom: 1rem;
    }
}
.preview {
    max-height: 500px;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 40px;

    .fade {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, white 20%, rgba(0, 0, 0, 0) 100%);
    }

    time {
        cursor: pointer;
    }
}

.source {
    margin-block-start: 20px;
}

.source,
.author {
    font-weight: 500;

    mark {
        background-color: transparent;
        font-weight: 600;
    }
}

@media screen and (min-width: 450px) {
    .article {
        &__title {
            font-size: 2.2rem;
        }
    }
}

@media screen and (min-width: 1000px) {
    .article {
        &__title {
            font-size: 3rem;
        }
    }
}

