@use "variables";

@mixin reset {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@mixin wrapper-desktop {
  max-width: 114rem;
  width: 100%;
  margin: 0 auto;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: variables.$scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(variables.$black, .1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(variables.$black, .2);
    border-radius: 10000px;

    &:hover {
      background-color: rgba(variables.$black, .3);
    }
  }
}

@mixin glass-bg {
  background-color: rgba(251, 251, 253, .8);
  backdrop-filter: saturate(180%) blur(2rem);
}
