@use '../../scss/utils/variables';

.blurEffect {
    position: relative;
    .effect {
        position: absolute;
        left: 0;
        top: .5rem;
        z-index: -1;
        transform: scaleX(.8);
        filter: blur(15px) saturate(12);
        opacity: 0;
        transition: variables.$transition;
    }

    &:hover {
        .effect {
            opacity: .3;
        }
    }
    img {
        width: 100%;

    }

}
