.app {
  max-width: 140rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  padding: 2rem;
  position: relative;
}

@media screen and (min-width: 800px) {
  .app {
    &::before {
      content: "";
      position: absolute;
      width: 30rem;
      height: 30rem;
      background-color: transparent;
      background-image: url("./img/distance.svg");
      left: 0;
      top: 20rem;
      z-index: -1;
    }
  }
}
