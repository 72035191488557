@use '../../scss/utils/variables';
@use '../../scss/utils/mixins';

.nav {
  position: sticky;
  top: 2rem;
  display: none;
  flex-direction: column;
  align-items: center;
  max-height: 1440px;
  height: calc(100vh - 4rem);
  width: 20rem;
  padding: 2rem;
  border-radius: 15px;
  @include mixins.glass-bg;
  box-shadow: 0 0 5rem 0 rgba(13, 17, 23, 0.05);
  flex-shrink: 0;
}

.nav__links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: .5rem;
}

.nav__linksItem {
  width: 2rem;
  height: 2rem;
  &:first-child {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.nav__link svg {
  width: 100%;
  height: 100%;
}


.logo {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: auto;
}

.item {
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;

  span,
  a {
    color: variables.$link;
    font-size: 20px;
  }

  &.active {
    span {
      color: variables.$orange;
    }
  }
}

.ads {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: auto;
  gap: 1rem;

  img {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    height: auto;
    box-shadow: 0 0 25px 0 rgba(13, 17, 23, 0.1);
    transition: variables.$transition;
  }
}

.authors {
  //margin-top: auto;

  span {
    font-size: 1.2rem;
  }

  a {
    font-size: 1.4rem;
  }
}


@media screen and (min-width: 800px) {
  .nav {
    display: flex;
  }
}