@use "mixins";
@use "variables";

*,
*::before,
*::after {
  @include mixins.reset;
  box-sizing: border-box;
}

html {
  font-size: 50%;
}

body {
  font-size: 1.6rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //overflow-x: hidden;

  font-family: "Noto Sans Display", -apple-system, BlinkMacSystemFont, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: variables.$black;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-synthesis: none;

  scroll-behavior: smooth;

  background-color: variables.$white;
  background-image: url('../../img/relief-bg.svg');
}

.title {
  font-size: 5rem;
  line-height: 1;
  font-weight: 700;
  color: variables.$orange;
  user-select: none;
  cursor: default;
  margin-bottom: 4rem;
  text-align: center;
}

.container {
  width: 140rem;
  margin: 0 auto;
}

::selection {
  color: variables.$white;
  background-color: variables.$link;
}

// TODO FIXME

a,
.link {
  color: variables.$link;
  transition: variables.$transition;

  &:hover {
    color: variables.$orange;
  }

  &.link_lg {
    font-size: 32px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  margin-top: 6rem;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.parsed {
  letter-spacing: .02rem;
  font-size: 1.4rem;

  span,
  a,
  p {
    max-width: 100%;
  }

  // fixme
  a:has(h3) {
    display: block;
    width: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    width: auto;
    height: 100%;
    max-height: 30rem;
    // fixme
    margin: 1rem auto !important;
    border-radius: .5rem;
  }

  strong {
    font-weight: 600;
    width: max-content;
  }

  u {
    text-decoration: underline;
    font-weight: unset;
  }

  em {
    font-style: italic;
    font-weight: unset;
  }


  a {
    display: inline-flex;
    align-items: center;
  }

  &--compact {
    img {
      max-height: 15rem;
    }
  }

  p, a, span, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.slick-slide div:focus {
  outline: none;
}

input,
textarea {
  outline: none;
  border: 0;
  background-color: white;
  color: variables.$black;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 15px;
  box-shadow: 0 0 25px 0 rgba(13, 17, 23, 0.1);
}


.ico {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .5rem;
}

.ql-align-center {
  text-align: center;
}

.paginationButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: variables.$link;
    color: white;
    outline: 0;
    border-radius: 100000px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 500;
    border: 1px solid transparent;
    transition: variables.$transition;

    &:first-child {
      margin-right: 10px;
    }

    &:hover:not(:disabled) {
      background: lighten(variables.$link, 5%);
    }

    &:disabled {
      cursor: default;
      opacity: .7;
    }
  }
}


@media screen and (min-width: 800px) {
  html {
    font-size: 62.5%;
  }

  .menu {
    margin-top: 0;
  }
  .main {
    margin-top: 0;
  }

  .parsed {
    font-size: 1.6rem;
  }

}