@use '../../../scss/utils/variables';
@use '../../../scss/utils/mixins';

.menu {
  @include mixins.glass-bg;

  width: 100%;
  height: 6rem;
  top: 0;
  right: 0;
  position: fixed;
  z-index: variables.$menu-z-index;
  transition: cubic-bezier(.4, 0, .6, 1) .2s;
  transition-property: height, background-color;

  &_active {
    height: 100svh;
    background-color: rgba(251, 251, 253, 1);

    .menu__content {
      opacity: 1;
      visibility: visible;
    }
  }
}

// block scrolling when menu is opened
body:has(.menu_active) {
  overflow: hidden;
}

.menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.menu__logo {
  width: 4rem;
  height: 4rem;
}

.menu__button {
  width: 2.8rem;
  height: 4rem;
  padding: .5rem;
  border: 0;
  background: none;
  color: variables.$black;
  cursor: pointer;

  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;

  $height: .2rem;
  $gap: .5rem;
  $top: calc((4rem - 3 * $height - 3 * $gap) / 2);

  span {
    display: block;
    position: absolute;
    height: $height;
    width: 100%;
    background: variables.$black;
    border-radius: 1000000px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: $top;
    }

    &:nth-child(2), &:nth-child(3) {
      top: $top + $gap * 2;
    }

    &:nth-child(4) {
      top: $top + $gap * 4;
    }
  }

  &_active {
    color: variables.$orange;

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: $top + $gap * 2;
      width: 0;
      left: 50%;
    }

    span:nth-child(1) {
      top: $top + $gap * 2;
      width: 0;
      left: 50%;
    }

  }
}

.menu__content {
  width: 100%;
  height: calc(100% - 7rem);
  opacity: 0;
  visibility: hidden;
  transition: cubic-bezier(.4, 0, .6, 1) .1s;
  transition-property: opacity;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
}

.menu__item {
  font-size: 2.6rem;
  font-weight: 600;
  color: variables.$black;
  padding: .2rem;

  &_active {
    color: variables.$link;
  }
}

.menu__ads {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    max-width: 30rem;
    width: 100%;
    max-height: 20rem;
    height: 100%;
    cursor: pointer;
    border-radius: .5rem;
    box-shadow: 0 0 2.5rem 0 rgba(13, 17, 23, 0.1);
    transition: variables.$transition;
  }
}

.menu__footer {
  text-align: center;
}


@media screen and (min-width: 800px) {
  .menu {
    display: none;
  }
}