.federationInfo {
    display: flex;
    flex-direction: column;
}

.federationInfoBlock {
    h3 {
        margin-bottom: 10px;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}