@use "../../scss/utils/variables";
@use "../../scss/utils/mixins";

.search {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  &__ico {
    position: absolute;
    right: 2rem;
    width: 2.5rem;
    height: 2.5rem;

    path {
      fill: rgba(variables.$black, .7);
    }
  }

  &__input {
    width: 100%;
    padding: .5rem 2rem;

    background-color: white;

    font-size: 1.6rem;

    &::placeholder {
      color: rgba(variables.$black, .7);
    }
  }
}


@media screen and (min-width: 500px) {
  .search {
    &__input {
      max-width: 40rem;
      font-size: 1.8rem;
    }
  }
}
