@use '../../scss/utils/variables';
@use '../../scss/utils/mixins';

.maps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.maps__regions, .maps__additional, .maps__region {
  @include mixins.glass-bg;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 100%;
}

.maps__regionName {
  color: variables.$orange;
  margin-bottom: 1rem;
}

.maps__places {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.maps__subtitle {
  font-weight: 600;
  font-size: 2rem;
}

.maps__title{
  color: variables.$orange;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.maps__list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(251, 251, 253, .8);
  backdrop-filter: saturate(180%) blur(2rem);
  padding: 2rem;
  border-radius: 1.5rem;
  gap: 2rem;

  img {
    border-radius: .5rem;
    //width: 20rem;
    min-width: 20rem;
    max-width: max(100%);
    max-height: 30rem;
    width: auto;
    display: block;
    margin: 0 auto;
  }

  &:not(:last-child) {
    margin-block-end: 20px;
  }
}

.map__content {
  display: flex;
  flex-direction: column;

  span {
    font-size: 18px;
    font-weight: 500;

    &:not(:last-child) {
      margin-block-end: 5px;
    }
  }
}


.name {
  font-size: 24px;
  font-weight: 600;
  margin-block-end: 5px;
}


.mapsBackButton {
  margin-inline-end: 1rem;
}


@media screen and (min-width: 800px) {
  .map {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .maps__title {
    font-size: 3rem;
  }
}
