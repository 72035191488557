/* noto-sans-display-100 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 100;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-100.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-200 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 200;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-200.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-300 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-300.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-regular - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-regular.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-italic - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: italic;
    font-weight: 400;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-italic.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-500 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-500.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-600 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-600.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-700 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-700.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-800 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-800.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
/* noto-sans-display-900 - cyrillic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans Display';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/noto-sans-display-v20-cyrillic_latin-900.svg#NotoSansDisplay') format('svg'); /* Legacy iOS */
}
